import React, { Component } from 'react';
import { Layout, Spin, PageHeader, Button, Modal, Card, Empty, List,Avatar } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { axiosPost } from '../Utils/RequestUtil';

const types = ["未归寝", "已归寝"];
const height = document.documentElement.clientHeight;
const gridStyle = {
    width: '100%',
    textAlign: 'center',
    fontSize: '14px',
    padding: '20px'
};
const infiniteContainer = {
    borderRadius: '4px',
    overflow: 'auto',
    padding: '8px 24px',
    height: height - 87 + 'px',
    marginTop: '10px'
};

export default class StudentExceptionList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: this.props.location.state.type,
            relkey: this.props.location.state.relkey,
            id: this.props.location.state.id,
            index: 0,
            size: 10,
            category: '未归寝',
            loading: false,
            visable: false,
            hasMore: true,
            data: [],
            total:0
        }
    }
    componentDidMount() {
        this.setState({loading:true});
        this.getExceptionData();
    }
    getExceptionData = () => {
        let param = {
            type: this.state.type==="以班级为单位"?"ORG":"DOR",
            index: this.state.index,
            size: this.state.size,
            relkey: this.state.relkey,
            category: this.state.category==="未归寝"?"001":"002",
            id:this.state.id
        };
        axiosPost('', param, 'Student', 4, (response) => {
            if (response.code === 0) {
                if(response.data.length<this.state.size){
                    this.setState({hasMore:false});
                }
                this.setState({ loading:false,total:parseInt(response.message),data: [...this.state.data,...response.data] });
            } else {
                this.setState({loading:false,hasMore:false});
            }
        });
    }
    changeType = () => {
        this.setState({
            visable: true
        })
    }
    cardClick = (item) => {
        this.setState({
            visable: false,
            category: item,
            loading: true,
            data:[],
            hasMore:true,
            index:0
        }, () => {
            this.getExceptionData();
        });
    }
    handleInfiniteOnLoad = () => {
        this.setState({ index: this.state.index + 1 });
        this.getExceptionData();
    }
    cancelChoice=()=>{
        this.setState({visable:false});
    }
    render() {
        return (
            <Layout style={{ height: '100%', padding: 0 }}>
                <Layout.Content className="list" style={{ height: '100%', padding: '5px', marginTop: 2, backgroundColor: '#fff' }}>
                    <Spin size="large" spinning={this.state.loading}>
                        <PageHeader
                            ghost={false}
                            style={{ borderBottom: '1px solid #e8e8e8'}}
                            bordered={true}
                            title={this.state.category}
                            subTitle={this.state.total+"人"}
                            tags={<div><Button key="3" type="primary" size="small" onClick={this.changeType}>切换统计类型</Button></div>}
                        >
                        </PageHeader>
                        <div className="infinite-container" style={infiniteContainer}>
                            <InfiniteScroll
                                initialLoad={false}
                                pageStart={0}
                                loadMore={this.handleInfiniteOnLoad}
                                hasMore={this.state.hasMore}
                                useWindow={false}
                            >
                                <List
                                    itemLayout="horizontal"
                                    dataSource={this.state.data}
                                    renderItem={item => (
                                        <List.Item key={item.key}>
                                            <List.Item.Meta
                                                avatar={(<Avatar shape="square" src={item.dataImage} />)}
                                                title={item.name+"（"+item.org+"）"}
                                                description={(<div>楼栋名称：{item.build}<br/>楼层名称：{item.floor}<br/>房间编号：{item.room}</div>)}
                                            />
                                        </List.Item>
                                    )}
                                >
                                </List>
                                {
                                    !this.state.hasMore?(<div style={{textAlign:"center",width:'100%',color:'#666',fontSize:'12px'}}>数据加载完毕</div>):(<div style={{textAlign:"center",width:'100%',color:'#666',fontSize:'12px'}}>加载中……</div>)
                                }
                            </InfiniteScroll>
                        </div>
                    </Spin>
                    <Modal
                        title="选择类型"
                        visible={this.state.visable}
                        footer={null}
                        onCancel={this.cancelChoice}
                    >
                        <Card>
                            {
                                types.length > 0 ? types.map(item => {
                                    return (
                                        <Card.Grid key={item} style={gridStyle} onClick={this.cardClick.bind(this, item)}>{item}</Card.Grid>
                                    )
                                }) : <Empty description='暂无数据' />
                            }
                        </Card>
                    </Modal>
                </Layout.Content>
            </Layout>
        )
    }
}