import React, { Component } from 'react';
import { Statistic, Row, Col, Spin, Alert, Card, Empty, Descriptions, PageHeader, Button, Layout, message,Modal } from 'antd';
import moment from "moment";
import { axiosPost } from '../Utils/RequestUtil';
import {queryParam} from '../Utils/StringUtil';
const { Header, Content, Footer } = Layout;
const gridStyle = {
    width: '33.333%',
    textAlign: 'center',
    fontSize:'12px',
    padding:'20px'
};
const gridStyle1 = {
    width: '50%',
    textAlign: 'center',
    fontSize:'14px',
    padding:'20px'
};

export default class StudentYearReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: {
                data: []
            },
            relation: [],
            current: {},
            year: (parseInt(moment().format("YYYY"))-1)+'',
            visible: false,
            years:[],
            yVisable:false
        }
    }
    componentDidMount() {
        this.getYears();
        this.getUser();

    }
    getYears=()=>{
        let year=parseInt(moment().format("YYYY"));
        let years=[];
        for(var i=0;i<=5;i++){
            years.push((year-i)+"");
        }
        this.setState({years:years});
    }
    getUser=()=>{
        let openId = queryParam('openId');
        let unionId = queryParam('unionId');
        this.setState({ loading: true });
        var param = {
            openid: openId,
            unionid: unionId
        }
        axiosPost('', param, 'User', 3, (response) => {
            if (response.code === 0) {
                if (response.data.relation.length === 1) {
                    this.setState({ relation: response.data.relation });
                    this.setState({ current: response.data.relation[0] }, () => {
                        this.getData();
                    })
                }
                else {
                    this.setState({  relation: response.data.relation }, () => {
                        this.setState({ loading: false,visible: true });
                    });
                }
            } else {
                message.destroy();
                message.info("请先绑定账号");
                window.location = "https://api.jhxhzn.com/jump.html";
            }
        });
    }
    getData = () => {
        this.setState({loading:true});
        var param = {
            relkey: this.state.current.RelKey,
            year: this.state.year
        }
        axiosPost('', param, 'Student', 3, (response) => {
            if (response.code === 0) {
                this.setState({loading:false,data:JSON.parse(response.data)});
            } else {
                this.setState({loading:false,data:{
                    data: []
                }});
                message.destroy();
                message.warning("暂未生成该学生的报表");
            }
        });
    }
    changeStudent = () => {
        this.setState({visible:true});
    }
    changeYear=()=>{
        this.setState({yVisable:true});
    }
    cardClick = (item) => {
        this.setState({ current: item,visible:false }, () => {
            this.getData();
        });
    }
    yCardClick = (item) => {
        this.setState({ year: item,yVisable:false }, () => {
            this.getData();
        });
    }
    cancelChoice=()=>{
        this.setState({visible:false,yVisable:false});
    }
    render() {
        const { data, relation,year,current,years } = this.state;
        return (
            <Layout style={{ height: '100%', padding: 0 }}>
                <Header style={{ padding: 0, backgroundColor: '#fff', height: '30px', position: 'fixed', zIndex: 1, width: '100%' }}>
                    <Row>
                        <Col span={24}>
                            <Alert message="当前数据为报表数据，仅供参考" style={{ borderWidth: 0, borderBottomWidth: 1, borderRadius: 0 }} type="warning" showIcon />
                        </Col>
                    </Row>
                </Header>
                <Content className="home" style={{ height: '100%', padding: '5px', marginTop: 38, backgroundColor: '#fff' }}>
                    <Spin size="large" spinning={this.state.loading}>
                        <PageHeader
                            ghost={false}
                            style={{ border: '1px solid #e8e8e8', borderRadius: 3, marginTop: 0, backgroundColor: '#f8f8f8' }}
                            bordered={true}
                            title={year}
                            subTitle="年度报表"
                            tags={<div><Button key="3" type="primary" size="small" onClick={this.changeYear}>切换年度</Button>&nbsp;&nbsp;<Button key="4" onClick={this.changeStudent} type="default" size="small">切换学生</Button></div>}
                        >
                            <Descriptions size="small" column={2}>
                                <Descriptions.Item label="">{current.TeaName}从{moment(data.start).format("YYYY-MM-DD")}至{moment(data.end).format("YYYY-MM-DD")}数据统计</Descriptions.Item>                            </Descriptions>
                        </PageHeader>
                        {
                            data.data.length > 0 ?
                                data.data.map(item => {
                                    return (
                                        <Card key={item.id} style={{ marginTop: 5, backgroundColor: '#f8f8f8' }}>
                                            <Row gutter={5}>
                                                <Col span={24}>
                                                    <h3>{item.name}</h3>
                                                </Col>
                                            </Row>
                                            <Row gutter={5}>
                                                {
                                                    item.data.map(sun => {
                                                        return (
                                                            <Col key={sun.id} span={12} style={{ marginTop: 5 }}>
                                                                <Card bordered={false}>
                                                                    <Statistic style={{ fontSize: 12 }} valueStyle={{ fontSize: 16, textAlign: "center" }} title={sun.name} value={sun.val === '全年消费高于平均值' || sun.val === '全年充值高于平均值' ? '高于平均值' : sun.val === '全年消费低于平均值' || sun.val === '全年充值低于平均值' ? '低于平均值' : sun.val} />
                                                                </Card>
                                                            </Col>
                                                        )
                                                    })
                                                }
                                            </Row>
                                        </Card>
                                    )
                                }) : <Empty style={{marginTop:10}} description='暂无数据' />
                        }
                    </Spin>
                    <Modal
                        title="点击学生姓名选择学生"
                        visible={this.state.visible}
                        footer={null}
                        onCancel={this.cancelChoice}
                    >
                        <Card>
                            {
                                relation.length > 0 ? relation.map(item => {
                                    return (
                                        <Card.Grid key={item.RelKey} style={gridStyle} onClick={this.cardClick.bind(this, item)}>{item.TeaName}</Card.Grid>
                                    )
                                }) : <Empty description='暂无数据' />
                            }
                        </Card>
                    </Modal>
                    <Modal
                        title="点击选择年份"
                        visible={this.state.yVisable}
                        footer={null}
                        onCancel={this.cancelChoice}
                    >
                        <Card>
                            {
                                years.length > 0 ? years.map(item => {
                                    return (
                                        <Card.Grid key={item} style={gridStyle1} onClick={this.yCardClick.bind(this, item)}>{item}</Card.Grid>
                                    )
                                }) : <Empty description='暂无数据' />
                            }
                        </Card>
                    </Modal>
                </Content>
                <Footer style={{ textAlign: 'center', backgroundColor: '#fff', fontSize: 14 }}>贵州金宏鑫科技有限公司 ©{moment().format("YYYY")}</Footer>
            </Layout>
        )
    }
}
