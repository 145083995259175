export function numberToMoney(number) {
     var money = number.toString().split('.')[0].replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
          return s + ','
     });
     var moneyArr = money.split('.');
     if (moneyArr.length > 1) {
          if (moneyArr[1].length > 1) {
               return money;
          }
          return money + '0';
     }
     return money + '.00'
}

export function numberToInt(number) {
     return number.toString().replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
          return s + ','
     });
}
export function isEmptyObject(obj) {
     for (var key in obj) {
          return false;
     }
     return true;
}
export function queryParam(name){
     var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
     var r = window.location.search.substr(1).match(reg);
     if (r != null) return unescape(r[2]);
     return null;
}