import axios from 'axios';
import {sign} from './SecurityUtil';

const version='1.0';
const base='http://117.187.12.43:41712/api';
const config = { showLoading: true }

export async function axiosPost (token, params,api,action, callback) {
    let longTime = new Date().getTime().toString().substring(0, 10);
    let body={
      action:action,
      time:longTime,
      sign:sign(params,longTime),
      version:version,
      parameters:params
    };
    axios.create({
      headers: {
        'content-type': 'application/json'
      }
    });
    axios.defaults.headers.token=token;
    let host = window.location.host;
    return axios.post(`${base}/${api}`, body, config).then(res => {
      if(res.data.code===6){
        window.location.href='http://'+host;
      }else{
        callback(res.data);
      }
    });
} 