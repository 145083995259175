import React,{Component} from "react";
import {BrowserRouter,Route,Switch} from "react-router-dom";
import StudentYearReport from "./Pages/StudentYearReport";
import StudentExceptionPush from "./Pages/StudentExceptionPush";
import StudentExceptionList from "./Pages/StudentExceptionList";
export default class RouterWrap extends Component{
    render(){
        return (
            <div id="router">
                <BrowserRouter>
                    <Switch>
                        <Route path="/year" component={StudentYearReport}/>
                        <Route path="/exception" component={StudentExceptionPush}/>
                        <Route path="/elist" component={StudentExceptionList}/>
                    </Switch>
                </BrowserRouter>
            </div>
        )
    }
}