import React from 'react';
import RouterWrap from './routers';
import './App.css';

const App = () => (
  <div className="App">
    <RouterWrap/>
  </div>
);

export default App;