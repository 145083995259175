import React, { Component } from 'react';
import { Row, Col, Spin, Card, Empty, PageHeader, Button, Layout, Modal, message } from 'antd';
import moment from "moment";
import { queryParam } from '../Utils/StringUtil';
import { axiosPost } from '../Utils/RequestUtil';
const { Content, Footer } = Layout;
const gridStyle = {
    width: '100%',
    textAlign: 'center',
    fontSize: '14px',
    padding: '20px'
};
const gridStyle1 = {
    width: '25%',
    textAlign: 'center',
    padding: '5px',
    backgroundColor:"#fff"
};
const types = ["以宿舍为单位", "以班级为单位"];
export default class StudentExceptionPush extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visable: true,
            currentType: '以宿舍为单位',
            loading: false,
            departData: [],
            relkey:queryParam('relkey')
        }
    }
    cardClick = (item) => {
        this.setState({
            visable: false,
            currentType: item,
            loading:true
        }, () => {
            let param={
                relkey:this.state.relkey,
                model:'1470706632{e6c67691-5900-4571-905b-ad414b19a5da}'
            };
            if (this.state.currentType === '以班级为单位') {
                param={
                    relkey:this.state.relkey,
                    model:'1523517808{bc82ef02-27de-47e1-b225-779ac60b93fe}'
                };
            }
            axiosPost('', param, 'Common', 0, (response) => {
                this.setState({loading:false});
                if (response.code === 0) {
                    this.setState({ departData: response.data.data });
                } else {
                    message.destroy();
                    message.info(response.message);
                }
            });
        });
    }
    changeType = () => {
        this.setState({
            visable: true
        });
    }
    itemClick = (item) => {
        this.props.history.push({ pathname: '/elist', state: { relkey:queryParam('relkey'),type:this.state.currentType,id:item.id } });
    }
    cancelChoice=()=>{
        this.setState({visable:false});
    }
    
    render() {
        return (
            <Layout style={{ height: '100%', padding: 0 }}>
                <Content className="exception" style={{ height: '100%', padding: '5px', marginTop: 2, backgroundColor: '#fff' }}>
                    <Spin size="large" spinning={this.state.loading}>
                        <PageHeader
                            ghost={false}
                            style={{ border: '1px solid #e8e8e8', borderRadius: 3, marginTop: 0, backgroundColor: '#f8f8f8' }}
                            bordered={true}
                            title={this.state.currentType}
                            subTitle=""
                            tags={<div><Button key="3" type="primary" size="small" onClick={this.changeType}>切换统计类型</Button></div>}
                        >
                        </PageHeader>
                        {
                            this.state.departData.length > 0 ?
                                this.state.departData.map(item => {
                                    return (
                                        <Card key={item.id} style={{ marginTop: 5, backgroundColor: '#f8f8f8' }} bodyStyle={{ textAlign: "left", padding: '10px' }}>
                                            <Row gutter={5}>
                                                <Col span={24} onClick={this.itemClick.bind(this, item)}>
                                                    <Button type="link" style={{ textAlign: "left" }}>{item.name}</Button>
                                                </Col>
                                            </Row>
                                            <Row gutter={5}>
                                                <Col span={24} style={{ marginTop: 5 }}>
                                                    <div>
                                                        {
                                                            item.childrens.length>0?item.childrens.map(sun => {
                                                                return (

                                                                    <Card.Grid key={sun.id} onClick={this.itemClick.bind(this, sun)} style={gridStyle1}>
                                                                        <Button type="link">{sun.name}</Button>
                                                                    </Card.Grid>

                                                                )
                                                            }): <div></div>
                                                        }
                                                    </div>
                                                </Col>

                                            </Row>
                                        </Card>
                                    )
                                }) : <Empty style={{ marginTop: 10 }} description='暂无数据' />
                        }
                    </Spin>
                    <Modal
                        title="选择类型"
                        visible={this.state.visable}
                        footer={null}
                        onCancel={this.cancelChoice}
                    >
                        <Card>
                            {
                                types.length > 0 ? types.map(item => {
                                    return (
                                        <Card.Grid key={item} style={gridStyle} onClick={this.cardClick.bind(this, item)}>{item}</Card.Grid>
                                    )
                                }) : <Empty description='暂无数据' />
                            }
                        </Card>
                    </Modal>
                </Content>
                <Footer style={{ textAlign: 'center', backgroundColor: '#fff', fontSize: 14 }}>贵州金宏鑫科技有限公司 ©{moment().format("YYYY")}</Footer>
            </Layout>
        )
    }
}